import { Injectable } from '@angular/core';
//import { User, UserPermissions } from '../../shared/models/user';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse ,HttpResponse} from '@angular/common/http';
import { AccountService } from './account.service';
import { User, UserPermissions } from '../models/user';
//import { debug } from 'util';

@Injectable({
    providedIn: 'root',
})

export class UserService {

    rootUrl: string;
    noAuthHeader: any;
    constructor(private http: HttpClient, private accountService: AccountService) {
        this.rootUrl = accountService.rootUrl;
        this.noAuthHeader = accountService.noAuthHeader;
    }

    getUserCAI(): Observable<string> {
        const reqHeader = new HttpHeaders({ 'No-Auth': 'True' }); //, 'No-Auth': 'True'
        //return this.http.get(this.rootUrl + '/api/User/GetUserCAI', { headers: reqHeader });
        return this.http.get(this.rootUrl + '/api/Auth/GetUserCAI',{responseType: 'text'});
        //return this.http.get(this.rootUrl + '/api/Values/Get?id=1', { headers: reqHeader });
    }

    userAuthentication(email: string, password: string) {
        const data = 'username=' + email + '&password=' + password + '&grant_type=password';
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-form-urlencoded', 'No-Auth': 'True' }); //, 'No-Auth': 'True'
        return this.http.post(this.rootUrl + '/token', data, { headers: reqHeader });
    }

    validateUser(userCriteria: User): Observable<User> {
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post<User>(this.rootUrl + '/api/User/ValidateUser', JSON.stringify(userCriteria), { headers: reqHeader });
    }

    validateUserWithJWT(userCAI: string) {
        return this.http.get<string>(this.rootUrl + '/api/Auth/ValidateUser?userCAI=' + userCAI);
    }

    validateAndGetUserDetails(userCAI: string) {
        console.log(userCAI);
        return this.http.get<string>(this.rootUrl + '/api/User/ValidateAndGetUserDetails?userCAI=' + userCAI);
    }

    getUserClaims(): Observable<User> {
        return this.http.get<User>(this.rootUrl + '/api/User/GetUserClaims');
    }

    // getUserPermissions(user: User) {
    //     return this.http.post<UserPermissions>(this.rootUrl + '/api/User/GetUserPermissions', JSON.stringify(user));
    // }

    getUserPermissions(userCAI: string) {                
        return this.http.get<UserPermissions>(this.rootUrl + '/api/account/GetUserPermissions?cai='+ userCAI);
    }

    isTest(): boolean {
        return true;
    }

    private handleError(error: HttpResponse<any>) {
        //this.spinnerService.hide();
        return (error.body);
    }
}
