import { Injectable } from '@angular/core';
//import { Http, Headers, Response, RequestOptions } from '@angular/http';
import {HttpClientModule,HttpResponse} from '@angular/common/http';
import { Observable } from "rxjs";
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './sessionStorage.service';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private _apiBaseURL = "http://localhost:57113/api/"
    private _apiURL = "";
    private _apiName = "";

    constructor(private http: HttpClientModule, private localStorageService: LocalStorageService, private sessionStorageService: SessionStorageService) {
      //This is empty
    }

    getHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
                //,'Access-Control-Allow-Origin': '*',
                //'Access-Control-Allow-Headers': '*'
            })
        };
        return httpOptions;
    }

    getAuthKeys() {
        return {
            sessionID: this.localStorageService.get("sessionID"),
            token: this.localStorageService.get("token")
        }

    }

    getNativeWindow() {
        return window;
    }

    // getAPIUrl(apiName): string {
    //     this._apiName = apiName;
    //     return this._apiBaseURL + this._apiName;
    // }

    clearLocalStorage() {
        this.localStorageService.remove("userToken");
        this.localStorageService.remove("userDetails");
    }

    removeUserCriterias() {
        this.localStorageService.remove('userToken');
    }

    getlocalStorageValueByKey(key: string) {
        return this.localStorageService.get(key);
    }

    setlocalStorageValueByKey(key: string, value: any) {
        this.localStorageService.set(key, value);
    }

    getSessionStorageValueByKey(key: string) {
        return this.sessionStorageService.get(key);
    }
    deletesessionsoragevaluebykey(key:string){
        this.sessionStorageService.remove(key);
    }

    setSessionStorageValueByKey(key: string, value: any) {
        this.sessionStorageService.set(key, value);
    }

    removelocalStorageValueByKey(key: string) {
        this.localStorageService.remove(key);
    }

    getCurrentUser(): any {
        let details = JSON.parse(this.localStorageService.get('userDetails') as string);
        return details;
    }

    private handleError(error: HttpResponse<any>) {
        return (error.body);
    }
}
