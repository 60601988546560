import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  readonly rootUrl = environment.baseURL;
  //readonly rootUrl = "https://localhost:7117";

  readonly noAuthHeader = new HttpHeaders({ 'No-Auth': 'True' });
  constructor(private http: HttpClient) { }

  getUserClaims() {
    return this.http.get(this.rootUrl + '/api/GetUserClaims');
  }

  getUserDemographics(): Observable<User> {
    return this.http.get<User>(this.rootUrl + '/api/Account/GetUserDemographics');
  }
}
