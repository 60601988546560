import { ChangeDetectorRef, Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { LoaderService } from './main-app/services/loader.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  loaderChangeSubject: Subscription;
  IsLoading: boolean = false;
  title = 'ClientApp';
  constructor(private loaderService: LoaderService, 
     private titleService: Title,
    private changeDetector: ChangeDetectorRef
  ) {
    this.titleService.setTitle(`SASBU PAT Tool - ${environment.name}`);

    this.loaderChangeSubject = this.loaderService.triggerLoaderChange.subscribe((data) => {
        if (data) {
          this.IsLoading = data.value;
          this.changeDetector.detectChanges();
        }
      }, _err => {
        this.IsLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.loaderChangeSubject.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  ngAfterViewInit() {
    this.IsLoading = false;
    this.changeDetector.detectChanges();
  }
  // ngAfterContentChecked(): void {
  //   this.IsLoading = false;
  //   this.changeDetector.detectChanges();
  // }


}
