import { ErrorHandler, Injectable } from '@angular/core';
//import { MonitoringService } from './monitoring.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggerService extends ErrorHandler {

  constructor() {
      super();
  }

  // handleError(error: Error) {
  //     this.loggingService.logException(error); // Manually log exception
  // }
}