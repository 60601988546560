import { HttpErrorResponse, HttpParams, HttpParamsOptions, HttpResponse } from '@angular/common/http';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, iif, of } from 'rxjs';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../../services/loader.service';
import { CalAngularService } from '@cvx/cal-angular';
import { MonitoringService } from '../../services/monitoring.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   throw new Error('Method not implemented.');
  // }

  constructor(private loaderService: LoaderService, private authService: CalAngularService, private monitoringService:MonitoringService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let scopes = this.getScopesForEndpoint(req.url);

    let _isSilentCall = (!req.params || !(req.params instanceof InterceptorHttpParams && req.params.interceptorConfig.isSilentCall));

    if (_isSilentCall) {
      this.loaderService.show();
    }

    return iif(() => (!scopes || scopes.length == 0), of(req), from(this.authService.getAADToken(scopes).toPromise().then((response: any) => {
        const authHeader = `Bearer ${response}`;
        return req.clone({
          setHeaders: {
            Authorization: authHeader
          }
        });
      })))
      .pipe(
        mergeMap((nextReq: HttpRequest<any>) => { 
          return next.handle(nextReq);
        
        }),
        tap((event: any) => {
            if (event instanceof HttpResponse) {
            }
            return event;
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              this.monitoringService.logException(err);
              // this.auth.clearCacheForScope(accessToken);
              // this.broadcastService.broadcast('msal:notAuthorized', err.message);
            }
            return this.throwErr(err);
          }
        ), finalize(() => {
          if (_isSilentCall) {
            this.loaderService.hide();
          }
        })
        // tap(n => {
        //     if (_isSilentCall) {
        //  this.loaderService.hide();
        // }
        
        
      );

  }

   throwErr(err:any): never {
    throw new Error('Something went wrong'+err);
  }
  private getScopesForEndpoint(endpoint: string): string[] {
    const env = environment;

    if (endpoint.indexOf(env.baseURL) >= 0) {
      return env.endpointScopes;
    }
    if (endpoint.indexOf("https://api.powerbi.com") >= 0) {
      return ['https://analysis.windows.net/powerbi/api/Report.Read.All', 'https://analysis.windows.net/powerbi/api/Dataset.Read.All'];
    }

    return [];
  }

}

export class InterceptorHttpParams extends HttpParams {
  constructor(
    public interceptorConfig: { isSilentCall: boolean },
    params?: { [param: string]: string | string[] }
  ) {
    super({ fromObject: params } as HttpParamsOptions);
  }
}
