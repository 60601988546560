import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
//import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/main-app/services';
import { PermissionsService } from 'src/app/main-app/services/permissions.service';
import { IMenuVM } from '../../../models/master.model';
import { DataTransferService } from '../../../services/data-transfer.service';
import { EmployeeService } from '../../../services/employee.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { MasterService } from 'src/app/main-app/services/master.service';
import { Location } from '@angular/common';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
}
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  profile!: ProfileType;

  menus: IMenuVM[] = [];
  //lbMenuId: number = 0;
  //lbMenus: IMenuVM[] = [];
  menuDetails:any[]=[];
  menuSubject: Subscription | null = null;
  selectedMenuId: number = 0;
  selectedSubMenuId: number = 0;
  isOnlySingleEdit: boolean = false;
  displayName: string = "";
  userCai:string="";
  loggedIn: boolean = false;
  currentUserProfile:any;
  provisioningId: any;

  constructor(private router: Router,
    private http: HttpClient, //private authService: MsalService,
    private authService: CalAngularService,
    private dataTransferService: DataTransferService,
    private employeeService: EmployeeService,
    private storageService: LocalStorageService,
    private userService: UserService,private route: ActivatedRoute,private Location:Location,
    private permissionsService: PermissionsService,private masterService: MasterService) {


  }

  ngOnInit(): void {
    //this.FillMenuDetails();

    this.authService.isUserSignedIn()
      .subscribe((value: any) => {
        console.log("value"+value)
        this.loggedIn = value;
        if (this.loggedIn) {
          this.currentUserProfile = this.authService.cvxClaimsPrincipal;
          this.displayName = this.currentUserProfile.name;
          this.provisioningId=this.currentUserProfile.provisioningId;
          this.FillMenuDetails();
        }
        else {
          console.log("user not yet signed in");
        }
      })
    //this.displayName = "Pardeshi,Nareshi[LTI]";
  }

  getSIMTPermissions(cai: string) {
    this.userService.getUserPermissions(cai.toLowerCase()).subscribe((response: any) => {
      this.permissionsService.set(response);
    });
  }


  FillMenuDetails()
  {
    
    let menuList: any[] ;
    menuList=[];
    this.masterService.GetMenuDataByProvisioningId(this.provisioningId).subscribe((res) => {
      this.menuDetails = res;
      console.log(this.menuDetails)
      for (let i = 0; i <  this.menuDetails.length; i++) {
        menuList.push({ 'menuId': this.menuDetails[i].menuId, 'name': this.menuDetails[i].menuName, 'urlRoute': this.menuDetails[i].url, 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null });
      }
      this.menus = menuList;
      this.MenuDefault();
    })
  }
  InitializeSubscription() {
   
    let menuList: any[] = [
      { 'menuId': 1, 'name': 'Home', 'urlRoute': 'home', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
      { 'menuId': 2, 'name': 'Admin', 'urlRoute': 'admin', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
      { 'menuId': 3, 'name': 'Allowance Master', 'urlRoute': 'allowance-master', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
      { 'menuId': 4, 'name': 'Employee Master', 'urlRoute': 'employee-master', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
      { 'menuId': 5, 'name': 'Payment Calculation', 'urlRoute': 'paymentcalculationhome', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
      { 'menuId': 6, 'name': 'Payment Calculation History', 'urlRoute': 'help', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
      { 'menuId': 7, 'name': 'Approver', 'urlRoute': 'help', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
      { 'menuId': 8, 'name': 'Help', 'urlRoute': 'help', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null }
    ];
    this.menus = menuList;
    this.MenuDefault();
  }

  ngOnDestroy(): void {
    this.menuSubject?.unsubscribe();
  }

  

  MenuDefault()
  {
    console.log("locationpath" +this.Location.path())
    let ActivatedRout=this.Location.path();
    let _urlRoute=ActivatedRout.split("/");
    //console.log("_urlRoute" +_urlRoute[1])
    this.SetMenu(this.menus,_urlRoute[1],1);

  }

  SetMenu(menus: IMenuVM[], urlRoute: string, menuLevel: number) {
   
    let _name: string = urlRoute;
    let _menu = menus.find(e => e.urlRoute == _name);
    if (_menu) {
      this.isOnlySingleEdit = _menu.onlySingleEdit;
      //Set menu id based on menu level.
      switch (menuLevel) {
        case 1:
          this.selectedMenuId = _menu.menuId;
          break;
        case 2:
          this.selectedSubMenuId = _menu.menuId;
          break;
      }
    }
  }
  AssignMenu(menus: IMenuVM[], urlSegment: string[], menuLevel: number) {
   
    let _name: string = urlSegment[0];
    let _menu = menus.find(e => e.urlRoute == _name);

    if (_menu) {

      this.isOnlySingleEdit = _menu.onlySingleEdit;

      //Set menu id based on menu level.
      switch (menuLevel) {
        case 1:
          this.selectedMenuId = _menu.menuId;
          break;
        case 2:
          this.selectedSubMenuId = _menu.menuId;
          break;
      }

      //If menu has sub menu then repeat.
      if (urlSegment.length > 1 || _menu.subMenus?.length > 0) {
        this.AssignMenu(_menu.subMenus, urlSegment.splice(1), menuLevel + 1);
      }


    }
  }

  OnNavClick(menuId: number) {
    if ((menuId || menuId == 0) && this.menus) {
      let _selectedMenu: any = this.menus.find((e: any) => e.menuId == menuId);

      if (_selectedMenu) {
        this.selectedMenuId = menuId;
        if (_selectedMenu.subMenus && _selectedMenu.subMenus.length > 0) {
          let _selectedSubMenu = _selectedMenu.subMenus[0];
          this.OnNavSubClick(menuId, _selectedSubMenu.menuId);
        } else {
          this.router.navigate([`/${_selectedMenu.urlRoute}`]);
        }
      }

    }
  }

  OnNavSubClick(menuId: number, subMenuId: number) {
    if ((menuId || menuId == 0) && this.menus) {
      let _selectedMenu: any = this.menus.find((e: any) => e.menuId == menuId);

      if (_selectedMenu) {
        let _selectedSubMenu: any = _selectedMenu.subMenus.find((e: any) => e.menuId == subMenuId);

        if (_selectedSubMenu) {
          this.selectedMenuId = menuId;
          this.selectedSubMenuId = subMenuId;

          this.router.navigate([`/${_selectedMenu.urlRoute}/${_selectedSubMenu.urlRoute}`]);
        }
      }

    }
  }

 
  getUserProfile(emailId?: string) {
    if (emailId) {
      this.employeeService.getUserProfile(emailId).subscribe((res) => {
          this.storageService.clearUserProfile();
          if (res) {
            this.storageService.saveUserProfile(res);
          }
        }, _error => {
          //print error
        });
    }

  }
}



