import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private loggedIn = new BehaviorSubject<boolean>(false); // {1}

    public token!: string;


    constructor(
        private router: Router, private route: ActivatedRoute,
        private commonService: CommonService
    ) { }

    isAuthenticatedUser(): boolean {
        let isUserAuthenticated = false;
        if (this.commonService.getlocalStorageValueByKey('userToken') != null) {
            isUserAuthenticated = true;
        }
        return isUserAuthenticated;
    }

    logout() {
        this.commonService.removeUserCriterias();
    }
}