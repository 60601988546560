export const environment = {
  name: "Dev",
  production: false,
  baseURL: "https://sasbuhrpact-api-dev.azure.chevron.com",
  UIbaseURL: "https://sasbuhrpact-dev.azure.chevron.com",
  clientId: "2d6e78b2-5d83-4546-b0e3-d8161eb2e54a",
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  endpointScopes: ['2d6e78b2-5d83-4546-b0e3-d8161eb2e54a/.default'],
  appInsights: {
    instrumentationKey: "f1c250e5-093d-4c9a-975c-1e66c6091fdf"
}
};
