import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatPaginatorModule } from '@angular/material/paginator'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NavbarComponent } from './main-app/shared/components/navbar/navbar.component';
import { FooterComponent } from './main-app/shared/components/footer/footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatListModule } from '@angular/material/list'
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';

/** */
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorLoggerService } from './main-app/services/error-logger.service';
import { pptDialog } from './main-app/shared/components/ppt-dialog/ppt-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { TableModule } from 'primeng/table';
import { CalAngularModule } from '@cvx/cal-angular';
import { AuthInterceptor } from './main-app/shared/interceptor/auth.interceptor';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { AuthGuardService } from './main-app/services/auth-guard.service';
import { AdminGuardService } from './main-app/services/admin-guard.service';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    pptDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TableModule,
    AutocompleteLibModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatTabsModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatDialogModule,

    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatSelectModule,
    //MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    //NgMultiSelectDropDownModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
    }),
    CalAngularModule.forRoot({
      autoSignIn: true,
      popupForLogin: false,
      instance: "https://login.microsoftonline.com/",
      tenantId: environment.tenantId,
      clientId: environment.clientId,
      // redirectUri will need to match a redirectUri setup in your app registration
      redirectUri: environment.UIbaseURL,
      oidcScopes: ["openid", "profile", "User.Read", "offline_access"],
      graphScopes: ["User.Read", "profile"],
      cacheLocation: "sessionStorage"
    }),
   
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: ErrorLoggerService },
    AuthGuardService,AdminGuardService
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
